<template>
  <div class="">
    <div class="onePageBgc slidJump">
      <!-- <img src="../assets/img/pageOne/leftBg.png" alt="" class="onePageBgc" /> -->
      <!-- <img src="../assets/img/pageOne/rightBg.png" alt="" class="onePageBgc" /> -->
      <el-row class="navBg">
        <el-col :span="24">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <div class="logoImg">
              <img src="../assets/img/logo.png" alt="" style="width: 0.66rem" />
            </div>
            <el-menu-item
              v-for="(item, index) in navList"
              :key="index"
              :index="item.path"
              @click="scrollToPosition(index)"
              >{{ item.name }}</el-menu-item
            >
            <!-- <el-menu-item index="2">产品介绍</el-menu-item>
            <el-menu-item index="3">帮助中心</el-menu-item>
            <el-menu-item index="4">联系我们</el-menu-item> -->
          </el-menu>
        </el-col>
      </el-row>
      <el-row class="margin">
        <el-col :span="12" class="onePage one flexIn" style="padding-left: 0">
          <div class="oneLeft">
            <h2 class="title">数智化⼝腔诊所<br />全场景运营管理服务系统</h2>
            <h3 style="width: 439px">Digital Intelligent Dental Clinic</h3>
            <h3 class="titleContent" style="margin-top: 0 !important">
              Full Scene Operation Management Service System.
            </h3>
            <p class="content">您的个人助理<br />口腔机构的数智管家</p>
            <!-- <el-button
              plain
              class="button1 btn2Color"
              @click="downLoadMac"
              style="width: 0.79rem; margin-right: 6px"
              ><img
                src="../assets/img/pageOne/apple.png"
                alt=""
                class="btnImg"
                style="position: relative; top: -0.01rem; font-size: 0.07rem"
              /><span class="imgFz" style="margin-left: 3px"
                >Mac版下载</span
              ></el-button
            >
            <el-button
              plain
              class="button1 btn1Color"
              @click="downLoadWindow"
              style="margin-top: 30px"
              ><img
                src="../assets/img/pageOne/window.png"
                alt=""
                class="btnImg"
                style="font-size: 0.07rem"
              /><span class="imgFz" style="margin-left: 3px">windows版下载</span>
            </el-button> -->

            <br />
            <el-button
              type="primary"
              plain
              class="button1 btn3Color"
              @click="tryOut"
              style="margin-top: 25px; width: 0.79rem"
              ><img
                src="../assets/img/pageOne/tryout.png"
                alt=""
                class="btnImg"
                style="font-size: 0.07rem"
              /><span class="imgFz" style="margin-left: 3px">申请试用</span>
            </el-button>
          </div>
        </el-col>
        <el-col :span="12" class="flexIn">
          <div class="layout">
            <img src="../assets/img/pageOne/computer.png" alt="" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="twoPageBgc slidJump">
      <el-row class="margin">
        <el-col :span="12">
          <div class="flexIn">
            <img
              src="../assets/img/pageTwo/content.png"
              alt=""
              style="width: 2.67rem"
            />
          </div>
        </el-col>
        <el-col :span="12" class="onePage two flexIn">
          <div class="oneLeft">
            <h2 class="title">流水牌</h2>
            <h3 class="content">
              精简每⽇业务，告别繁琐<br />抓住每⼀条客户信息和销售线索
            </h3>
            <p class="text">
              降低⼀线员⼯的负担 帮助⽼板实现轻松管理 促成机构战略落地执⾏
            </p>
            <div class="textMarginTop">
              <!-- <span class="boxShadow"> -->
              <img
                src="../assets/img/pageTwo/icon1.png"
                alt=""
                style="width: 0.26rem; height: 0.26rem"
              />
              <!-- </span> -->
              <span class="textMargin">个性化的服务</span>
            </div>
            <div class="textMarginTop">
              <!-- <span class="boxShadow"> -->
              <img
                src="../assets/img/pageTwo/icon2.png"
                alt=""
                style="width: 0.26rem; height: 0.26rem"
              />
              <!-- </span> -->
              <span class="textMargin">全方位的覆盖</span>
            </div>
            <div class="textMarginTop">
              <!-- <span class="boxShadow"> -->
              <img
                src="../assets/img/pageTwo/icon3.png"
                alt=""
                style="width: 0.26rem; height: 0.26rem"
              />
              <!-- </span> -->
              <span class="textMargin">规范化的流程</span>
            </div>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
                class="btnImg"
              /><span class="imgFz">申请试用</span></el-button
            > -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="threePageBgc">
      <el-row class="margin">
        <el-col :span="12" class="onePage one flexIn">
          <div class="oneLeft">
            <h2 class="title">照片管理</h2>
            <p class="content">轻松管理患者术前、术中、术后照⽚</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flexIn">
            <img
              src="../assets/img/pageThree/content.png"
              alt=""
              style="width: 3.2rem"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="fourPageBgc">
      <el-row class="margin">
        <el-col :span="12">
          <div class="flexIn">
            <img
              src="../assets/img/pageFour/content.png"
              alt=""
              style="width: 2.65rem"
            />
          </div>
        </el-col>
        <el-col :span="12" class="onePage three flexIn">
          <div class="oneLeft">
            <h2 class="title">SCRM与⼝腔智联</h2>
            <p class="content">您与客户的距离其实并不遥远</p>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
                style="btnImg"
              /><span class="imgFz">申请试用</span></el-button
            > -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="fivePageBgc">
      <el-row class="margin">
        <el-col :span="12">
          <div class="flexIn">
            <img
              src="../assets/img/pageFive/content.png"
              alt=""
              style="width: 2.8rem"
            />
          </div>
        </el-col>
        <el-col
          :span="12"
          class="onePage three flexIn"
          style="padding: 0 0.8rem"
        >
          <div class="oneLeft">
            <h2 class="title">AI智能的嵌入</h2>
            <p class="content" style="font-size: 0.07rem">
              睿齿云让您自然而然的应用到AI的能力，使之成为一种习惯和辅助手段，无需繁琐的设置和操作，帮助您更准更快的完成工作，别人还在加班完成Excel表格，您已经约上三五好友小酌一杯，这就是AI的力量。
            </p>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
              />申请试用</el-button
            > -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="sixPageBgc">
      <el-row class="margin">
        <el-col :span="12" class="flexIn">
          <div>
            <img
              src="../assets/img/pageSix/content.png"
              alt=""
              style="width: 2.65rem"
            />
          </div>
        </el-col>
        <el-col
          :span="12"
          class="onePage three flexIn"
          style="padding: 0 0.8rem"
        >
          <div class="oneLeft">
            <h2 class="title">报表</h2>
            <h3
              style="
                margin-top: 0.14rem;
                font-size: 0.09rem;
                color: #133aff;
                font-weight: bold;
              "
            >
              实时 + 准确 + 全面 + 灵活
            </h3>
            <p class="content" style="font-size: 0.07rem">
              口腔机构管理数据化，运营情况多维度呈现，客户资产沉淀，支撑运营管理决策。
            </p>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
                class="btnImg"
              /><span class="imgFz">申请试用</span></el-button
            > -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="sevenPageBgc">
      <el-row class="margin">
        <el-col
          :span="12"
          class="onePage seven flexIn"
          style="padding: 0 0.8rem"
        >
          <div class="oneLeft">
            <h2 class="title">一体化</h2>
            <p class="content">
              面向数据中台的设计，可以有效连接您的财务系统、OA系统、医疗系统、进销存系统
              等，同时，突破了平台的限制，让你的 客户小程序 , 员工APP ，甚至是
              企业微信 全部协同起来，打破数据孤岛，让数据的价值最大化。
            </p>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
                class="btnImg"
              /><span class="imgFz">申请试用</span></el-button
            > -->
          </div>
        </el-col>
        <el-col :span="12" class="flexIn">
          <div>
            <img
              src="../assets/img/pageSeven/content.png"
              alt=""
              style="width: 2.4rem"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="eightPageBgc">
      <el-row class="margin">
        <el-col :span="12" class="flexIn">
          <div>
            <img
              src="../assets/img/pageEight/content.png"
              alt=""
              style="width: 2.4rem"
            />
          </div>
        </el-col>
        <el-col :span="12" class="onePage three flexIn">
          <div class="oneLeft">
            <h2 class="title">迭代速度快</h2>
            <h3
              style="
                margin-top: 0.14rem;
                font-size: 0.09rem;
                font-family: 'PingFang SC';
                color: #0e0e5e;
              "
            >
              快速的市场变化需要快速的响应能力。
            </h3>
            <p class="content" style="width: 2.325rem font-size: 0.07rem">
              每两周⼀次功能级别更新。采⽤先进的、具备完整底层知识产权技术的架构，结合业界成熟稳定的AI技术，
              带来更强的市场需求响应能力和更⾼效的使用体验，伴随机构和行业一起成长。
            </p>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
                class="btnImg"
              /><span class="imgFz">申请试用</span></el-button
            > -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="ninePageBgc slidJump">
      <el-row class="margin">
        <el-col :span="24" class="onePage nine">
          <div class="oneLeft">
            <h2 class="title">帮助中心</h2>
            <p class="content" style="">
              互联网时代，唯快不破，快速的市场变化需要快速的响应能力。
            </p>
          </div>
          <div class="cardList" @click="open">
            <vue-seamless-scroll
              :data="newsList"
              :class-option="optionLeft"
              class="seamless-warp2"
            >
              <ul class="loopBox">
                <li>
                  <div class="box">
                    <p class="triangle"></p>
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon1.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle">企业模块</h2>
                    <p class="p1">企业加工件界面介绍</p>
                    <p class="p2">库存与采购界面介绍</p>
                    <p class="p3">架构、人力、通知界面介绍</p>
                  </div>
                </li>
                <li>
                  <div class="box" style="background: #5e57ea">
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon2.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle" style="color: #fff">流水牌</h2>
                    <p class="p1" style="color: #fff">划扣界面介绍</p>
                    <p class="p2" style="color: #fff">回访界面介绍</p>
                    <p class="p3" style="color: #fff">接待界面介绍</p>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <p class="triangle"></p>
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon3.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle">客户模块</h2>
                    <p class="p1">基础界面介绍</p>
                    <p class="p2">治疗界面介绍</p>
                    <p class="p3">维护界面介绍</p>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <p class="triangle"></p>
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon4.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle">客户抽屉</h2>
                    <p class="p1">口腔检查界面及功能介绍</p>
                    <p class="p2">正畸功能介绍</p>
                    <p class="p3">治疗计划界面及功能介绍</p>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <p class="triangle"></p>
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon5.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle">基础模块</h2>
                    <p class="p1">病历模板界面介绍</p>
                    <p class="p2">系统配置界面介绍</p>
                    <p class="p3">项目界面介绍</p>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <p class="triangle"></p>
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon6.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle">常见问题</h2>
                    <p class="p1">咨询相关问题</p>
                    <p class="p2">报备相关问题</p>
                    <p class="p3">接待相关问题</p>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <p class="triangle"></p>
                    <p class="boxImg">
                      <img
                        src="../assets/img/pageNine/icon7.png"
                        alt=""
                        style="width: 0.3rem; height: 0.3rem"
                      />
                    </p>
                    <h2 class="boxTitle">其他</h2>
                    <p class="p1">【首页】系统界面及功能介绍</p>
                    <p class="p2">患者建档</p>
                  </div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tenPageBgc slidJump">
      <el-row class="margin">
        <el-col :span="12" class="onePage one flexIn">
          <div class="oneLeft">
            <h2 class="title">越简单，越不凡</h2>
            <p class="content" style="font-size: 0.07rem; padding-right: 1rem">
              我们的期望是您与系统的交互越少越好，我们希望把复杂的东西交给系统在背后默默的完成，而您，只需要专注到真正有生产力的工作当中，睿齿云就像一座冰山，在您看到的部分下，隐藏着大量的细节，如果您有兴趣来挖掘，欢迎联系我们！
            </p>
            <!-- <el-button type="primary" class="btn"
              ><img
                src="../assets/img/pageTwo/icon4.png"
                alt=""
                class="btnImg"
              /><span class="imgFz">申请试用</span></el-button
            > -->
            <br />
            <div>
              <div class="QRcode">
                <img
                  src="../assets/img/pageTen/QRcode.png"
                  alt=""
                  style="width: 0.55rem; height: 0.55rem"
                />
                <p class="QRtext">扫一扫关注更多动态</p>
              </div>
              <div class="QRcode">
                <img
                  src="../assets/img/pageTen/service.png"
                  alt=""
                  style="width: 0.55rem; height: 0.55rem"
                />
                <p class="QRtext">扫一扫联系客服</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="flexIn">
          <div>
            <img
              src="../assets/img/pageTen/content.png"
              alt=""
              style="width: 2.5rem"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <div class="split"></div>
      <div>
        <div style="margin-top: 20px">
          <span class="margin">新骅光医疗科技集团有限公司</span>
          <span class="margin">版权所有</span>
          <span class="windowOpen margin" @click="windowOpen"
            >蜀ICP备2021032076号-1</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goAnchor, GetQueryString } from "../utils/anchor";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Home",
  components: { vueSeamlessScroll },
  data() {
    return {
      activeIndex: "1",
      navList: [
        {
          name: "首页",
          path: "1",
        },
        {
          name: "产品介绍",
          path: "2",
        },
        {
          name: "帮助中心",
          path: "3",
        },
        {
          name: "联系我们",
          path: "4",
        },
      ],
      newsList: [{}, {}, {}, {}, {}, {}],
    };
  },
  computed: {
    optionLeft() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {},
  mounted() {
    // let maodian = this.GetQueryString("anchor"); //进入页面，如果带有锚点参数，则跳转至锚点地方，参数值就是id名
    // if (maodian) {
    //   console.log(maodian);
    //   this.goAnchor("." + maodian);
    // }
  },
  methods: {
    goAnchor,
    GetQueryString,
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    scrollToPosition(index) {
      if (index === 2) {
        this.open();
      } else {
        // console.log(document.getElementsByClassName("slidJump"));
        document.getElementsByClassName("slidJump")[index].scrollIntoView({
          behavior: "smooth", //定义动画过渡效果"auto"或 "smooth" 之一。默认为 "auto"。
          // block: 'start', //定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"。
          // inline: 'nearest', //"start", "center", "end", 或 "nearest"之一。默认为 "nearest"。
        });
      }
    },
    open() {
      window.open("http://47.108.233.89:9090/", "_blank");
    },
    downLoadWindow() {
      window.location.href =
        "https://download.redental.cn/Redental-5.10.1-ia32-win.zip";
    },
    downLoadMac() {
      window.location.href = "https://download.redental.cn/Redental-5.10.1.dmg";
    },
    windowOpen() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
    tryOut() {
      window.open(
        "https://doc.weixin.qq.com/forms/ALYAZgcQABAAeUAAgYPAHAWvq108jEQ2f",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.seamless-warp2 {
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  ul.item {
    width: 100%;
    li {
      float: left;
      margin-right: 10px;
    }
  }
}
.flexIn {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-menu-item {
  font-size: 0.07rem;
  color: #303133;
  padding: 0 0.1rem;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}
.el-button {
  padding: 0.06rem 0.1rem;
}
.el-menu-demo {
  padding-left: 50%;
  height: 0.45rem;
}
.el-menu {
  background: none;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #0e0e5e;
  color: #0e0e5e;
}
.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 0.45rem;
  line-height: 0.45rem;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu-item {
  margin: 0 0.15rem !important;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.logoImg {
  position: absolute;
  left: 1.5rem;
  top: 0.25rem;
  margin-top: -0.05rem;
}
.onePageBgc {
  background: url("../assets/img/pageOne/bgc.png") no-repeat right top;
  background-size: 100% 100%;
  position: relative;
}
.twoPageBgc {
  background: url("../assets/img/pageTwo/bgc.png") no-repeat 0.25rem -0.3rem;
  background-size: 100% 100%;
  position: relative;
}
.threePageBgc {
  background: url("../assets/img/pageThree/bgc.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.fourPageBgc {
  background: url("../assets/img/pageFour/bgc.png") no-repeat -0.5rem;
  background-size: 100% 100%;
  position: relative;
}
.sixPageBgc {
  background: url("../assets/img/pageSix/bgc.png") no-repeat -0.5rem;
  background-size: 100% 100%;
  position: relative;
}
.sevenPageBgc {
  background: url("../assets/img/pageSeven/bgc.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.margin {
  margin-left: 15%;
  margin-right: 15%;
}
.onePage {
  height: 4rem;
}
.one {
  .title {
    font-size: 0.18rem;
    font-family: "PingFang SC";
    width: 2.1rem;
    color: #0e0e5e;
  }
  h3 {
    font-size: 0.09rem;
    font-family: "Din";
    margin-top: 0.08rem;
    font-weight: "Regular";
    color: #0e0e5e;
  }
  .content {
    margin-top: 0.14rem;
    color: #6e6e9e;
    font-size: 0.09rem;
  }
  .button1 {
    margin-top: 0.08rem;
    border-color: #3330e1;
  }
  .btn1Color {
    background-color: #fff;
    border: none;
    color: #3330e1;
    border: 1px solid #3330e1;
  }
  .btn2Color {
    color: #3330e1;
  }
  .btn3Color {
    background: #3330e1;
    color: #fff;
  }
}
.two {
  .title {
    color: #0e0e5e;
    font-size: 0.14rem;
    font-family: "PingFang SC";
    font-weight: bold;
  }
  .content {
    margin-top: 0.14rem;
    font-size: 0.09rem;
    font-family: "PingFang SC";
    font-weight: Regular;
    color: #0e0e5e;
  }
  .text {
    margin-top: 0.12rem;
    color: #6e6e9e;
    font-family: "PingFang SC";
    font-size: 0.07rem;
  }
  .textMargin {
    margin-left: 0.05rem;
    font-size: 0.095rem;
    font-weight: bold;
    font-family: "PingFang SC";
    color: #1f1231;
  }
  .textMarginTop {
    margin-top: 0.06rem;
    .boxShadow {
      border-radius: 50%;
      box-shadow: 0.025rem 0.015rem 0.16rem rgb(204 213 255 / 20%);
    }
  }
}
.btn {
  margin-top: 0.2rem;
  width: 0.625rem;
  height: 0.26rem;
  font-size: 0.07rem !important;
  background: #3330e1;
  color: #fff !important;
  border: none;
  font-weight: bold;
  font-family: "PingFang SC";
  img {
    margin-bottom: 0.01rem;
  }
}
.three {
  .title {
    color: #0e0e5e;
    font-size: 0.14rem;
    font-family: "PingFang SC";
    font-weight: bold;
  }
  .content {
    margin-top: 0.14rem;
    font-size: 0.09rem;
    color: #6e6e9e;
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 0.07rem;
  }
  .btn {
    margin-top: 0.08rem;
    width: 0.625rem;
    height: 0.26rem;
    font-size: 0.06rem;
    color: #3330e1;
    border-color: #3330e1;
    font-weight: bold;
    font-family: "PingFang SC";
    img {
      margin-bottom: 0.01rem;
    }
  }
}
.seven {
  .title {
    color: #0e0e5e;
    font-size: 0.14rem;
    font-family: "PingFang SC";
    font-weight: bold;
  }
  .content {
    width: 2.15rem;
    margin-top: 0.145rem;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #6e6e9e;
    font-size: 0.07rem;
  }
}
.nine {
  padding-top: 0.6rem !important;
  .title {
    font-size: 0.14rem;
    color: #0e0e5e;
    font-family: "PingFang SC";
    font-weight: bold;
  }
  .content {
    margin-top: 0.14rem;
    font-size: 0.09rem;
    line-height: 0.125rem;
    color: #6e6e9e;
  }
  .cardList {
    margin-top: 0.315rem;
    .box {
      width: 1.23rem;
      height: 1.6rem;
      border-radius: 0.04rem;
      box-shadow: 0.05rem 0.05rem 0.05rem rgb(204 213 255 / 30%);
      position: relative;
      padding: 0.15rem;
      .boxTitle {
        margin-top: 0.035rem;
        font-size: 0.09rem;
        font-family: "PingFang SC";
        font-weight: bold;
        color: #0e0e5e;
        line-height: 0.125rem;
      }
      .p1 {
        margin-top: 0.105rem;
        font-size: 0.07rem;
      }
      .p2,
      .p3 {
        margin-top: 0.02rem;
      }
      .p1,
      .p2,
      .p3 {
        font-size: 0.07rem;
        color: #6e6e9e;
      }
    }
    .triangle {
      width: 0;
      height: 0;
      border-top: 0.25rem solid #5e57ea;
      border-right: 0.25rem solid transparent;
      border-left: 0.25rem solid transparent;
      border-bottom-right-radius: 0.05rem;
      transform: rotate(-135deg);
      position: absolute;
      right: -0.16rem;
      top: -0.035rem;
    }
  }
}

.btnSp {
  margin-top: 0.08rem;
  width: 0.625rem;
  height: 0.26rem;
  font-size: 0.06rem;
  color: #3330e1;
  border-color: #3330e1;
  font-weight: bold;
  font-family: "PingFang SC";
  img {
    margin-bottom: 0.01rem;
  }
}
.QRcode {
  display: inline-block;
  margin-top: 0.08rem;
  margin-right: 0.08rem;
  border: 1px solid #5e57ea;
  border-radius: 0.04rem;
  width: 0.62rem;
  height: 0.62rem;
  text-align: center;
  padding-top: 0.025rem;
  .QRtext {
    margin-top: 0.05rem;
    color: #808591;
    font-size: 0.06rem;
  }
}
.loopBox {
  display: flex;
  .box {
    margin-left: 0.05rem;
    // flex-shrink: 0;
    cursor: pointer;
  }
}

.footer {
  margin-left: 15%;
  margin-right: 15%;
  border-top: 1px solid #a9acb46b;
  height: 0.5rem;
  text-align: center;
  font-size: 16px;
  .windowOpen {
    text-align: center;
    cursor: pointer;
  }
  .margin {
    margin: 0 10px;
  }
}
.layout {
  img {
    width: 3.15rem;
    height: 1.77rem;
  }
}
.btnImg {
  width: 0.1rem;
}
.imgFz {
  font-size: 0.07rem;
}
</style>
